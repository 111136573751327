@import '~monday-ui-style/dist/index.min.css';
@import '~monday-ui-react-core/src/styles/themes.scss';

.chart {
  display: flex;
  flex-grow: 1;
  margin-right: 44px;
  gap: 24px;

  &_wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
  }

  &_divider-wrapper {
    width: calc(100% + 16px);

    position: absolute;
    bottom: 0;
    left: -8px;
    z-index: -1;
  }

  &_count-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  &_count {
    position: relative;

    font-weight: 400;
    font-size: 12px;
    line-height: 22px;

    &:first-child {
      bottom: 12px;
    }

    &:last-child {
      top: 12px;
    }
  }
}

.middle {
  bottom: 50%;
}

.top {
  top: 0;
}

.divider {
  margin: 0 !important;
}
