@import '~monday-ui-react-core/src/styles/themes.scss';

.settings {
  padding: 16px 12px 32px;
  width: 296px;
  overflow-y: scroll;
  margin-top: 70px;
  scrollbar-color: #c5c7d0 transparent;
  scrollbar-width: thin;
  @include theme-prop(color, primary-text-color);

  &_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_sub-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
  }

  &_dropdown {
    width: 256px;
    z-index: 100;
    .menu {
      z-index: 100;
    }
  }

  &_block {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 256px;
  }

  &_dropdown-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
  }

  &_radio_btn-group {
    display: flex;
    flex-direction: column;

    &__subtitle {
      margin-bottom: 18px;
    }
  }

  &_accordion {
    width: 100%;
    margin-bottom: 16px;

    .expand-collapse--wrapper .expand-collapse__header {
      padding: 0 16px;
      height: 48px;
    }
    .expand-collapse--wrapper .expand-collapse__content {
      padding: 0;
    }

    .element-type-h5 {
      font-weight: 400;
    }

    &_item {
      padding: 16px 16px;
    }

    &:last-child {
      margin-bottom: 90px;
    }
  }

  &_group-btn {
    button {
      width: 100%;
    }
  }
}

.radio-btn {
  & + & {
    margin-top: 16px;
  }
}

.spacing {
  padding: 16px 0 8px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  @include theme-prop(background, ui-border-color);
  border-radius: 4px;
}

input:checked + .monday-style-radio-button-component__radio-input-container__radio-control {
  border-width: 0.2em !important;
}

.monday-style-radio-button-component__radio-label {
  text-align: left;
  max-width: 230px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.monday-style-radio-button-component__radio-input-container__radio-control {
  box-sizing: border-box;
}

.monday-style-tooltip {
  z-index: 1000;
}
