@import '~monday-ui-react-core/src/styles/themes.scss';
@import '../../variables.scss';

.funnel-view {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px 16px;

  &.settings-mode-height {
    margin-top: 70px;
    padding-top: 50px;
    height: 76%;
  }

  &_label {
    border: 1px solid;
    border-radius: 4px;
    @include theme-prop(color, secondary-text-color);
    @include theme-prop(border-color, secondary-text-color);
    line-height: 22px;
    margin-top: 4px;
    padding: 4px 8px;
  }

  .settings-mode-label {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  &_title {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    padding: 11px 0 11px 24px;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }

  &_filter-wrapper {
    display: flex;
    align-items: center;
    margin: 0 0 12px;
    padding-left: 12px;
    gap: 8px;
  }

  &_filter-outer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
  }

  &_chart-outer {
    display: flex;
    flex: 1;
    padding: 54px 12px 100px;
  }

  .settings-button {
    svg {
      @include theme-prop(color, secondary-text-color);
    }
  }

  .conversion-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .arrow {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      .triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 8.7px 5px;
        border-color: transparent transparent var(--layout-border-color) transparent;
      }

      .row {
        height: 100%;
        width: 2px;
        @include theme-prop(background, layout-border-color);
      }

      .triangle-bottom {
        transform: rotate(-180deg);
      }
    }

    &_content {
      padding: 8px 0;

      &-count {
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: bold;
        color: var($conversion-color);
      }

      &-text {
        width: 120px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
      }
    }
  }
}

.monday-style-dialog-content-wrapper {
  z-index: 50;
}

.date-dropdown,
.person-dropdown {
  width: 240px;
  .menu {
    z-index: 10;
  }
}

.person-dropdown {
  .menu {
    padding: 0 8px;
  }
}

.point {
  width: 4px;
  height: 4px;
  @include theme-prop(background, secondary-text-color);
  border-radius: 50%;
}

.dropdown-scroll {
  .menu {
    padding-right: 0;
  }
}

::-webkit-scrollbar {
  width: 8px;
  position: relative;
}
