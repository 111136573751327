@import '~monday-ui-react-core/src/styles/themes.scss';
@import './../../variables.scss';

.bar {
  max-width: 48px;
  min-width: 10px;
  border-radius: 4px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  flex-grow: 1;
  position: relative;

  font-style: normal;
  font-size: 12px;

  &--title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: absolute;
    bottom: -24px;
    left: 0;
    text-align: left;
  }

  &--count {
    position: absolute;
    top: -28px;
    font-weight: 700;
    white-space: nowrap;
    pointer-events: none;
  }

  &_polygon {
    width: 100%;
    height: 100%;
    opacity: 25%;
    position: relative;
    z-index: -1;

    &-wrapper {
      min-width: 30px;
      height: 100%;

      display: flex;
      justify-content: center;
      position: relative;
      flex-grow: 2;
    }

    &_arrow {
      position: absolute;
      z-index: 0;
      right: 8px;

      background-image: url(../../assets/arrow/arrow_light.svg);
      background-repeat: no-repeat;
      background-size: contain;

      display: flex;
      align-items: center;
      width: 52px;
      padding-left: 4px;

      font-weight: 400;
      font-size: 14px;
      height: 24px;
    }
  }

  .title__media-query__sm {
    @media (min-width: 300px) {
      width: 40px;
    }

    @media (min-width: 1100px) {
      width: 60px;
    }

    @media (min-width: 1250px) {
      width: 80px;
    }

    @media (min-width: 1400px) {
      width: 100px;
    }
  }

  .title__media-query__md {
    @media (min-width: 300px) {
      width: 50px;
    }

    @media (min-width: 1100px) {
      width: 80px;
    }

    @media (min-width: 1250px) {
      width: 100px;
    }

    @media (min-width: 1400px) {
      width: 120px;
    }
  }

  .title__media-query__lg {
    @media (min-width: 300px) {
      width: 80px;
    }

    @media (min-width: 1100px) {
      width: 120px;
    }

    @media (min-width: 1250px) {
      width: 160px;
    }

    @media (min-width: 1400px) {
      width: 200px;
    }
  }
}

.tooltip {
  &-title {
    display: flex;
    align-items: center;
  }

  &-dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 8px;
  }

  &-amount {
    font-weight: 700;
  }
}

.dark-app-theme {
  .bar_polygon_arrow {
    background-image: url(../../assets/arrow/arrow_dark.svg);
  }
}

.black-app-theme {
  .bar_polygon_arrow {
    background-image: url(../../assets/arrow/arrow_night.svg);
  }
}
