.empty-state {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &_img {
    width: 240px;
    height: 150px;
    margin-bottom: 32px;

    @media (min-height: 426px) {
      height: 230px;
      width: 320px;
    }
  }

  &_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
  }
}
