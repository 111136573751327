@import '~monday-ui-react-core/src/styles/themes.scss';

@keyframes fadeIn {
  0% {
    opacity: 0;
    z-index: -10000;
    transform: translateY(var(--dropdown-menu-fadinY, -75px));
  }
  100% {
    opacity: 1;
    z-index: 999;
    transform: translateY(0px);
  }
}

.value-container {
  align-items: center;
  display: flex;
  flex: 1;
  padding: 2px 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding-left: 12px;
  font-size: 14px;
  line-height: 14px;
  color: var(--primary-text-color);
  height: 30px;
  border-radius: var(--border-radius-small);
  position: relative;
}

.control {
  height: 100%;
  align-items: center;
  background-color: var(--primary-background-color);
  border: 0 solid transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 30px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  color: var(--primary-text-color);
}

.single-value {
  color: var(--primary-text-color);
  margin-left: 2px;
  margin-right: 2px;
  width: 100%;
  max-width: calc(100% - 8px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 14px;
  background-color: var(--primary-background-color);
  display: flex;
  align-items: center;
  height: 100%;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__label {
    display: flex;
    height: 100%;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.indicator {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 14px;
  color: var(--primary-text-color);
  background-color: var(--primary-background-color);
  border-radius: var(--border-radius-small);
  height: 30px;
  background: transparent;
}

.dropdown-indicator {
  color: var(--icon-color);
  display: flex;
  padding: 0px;
  transition: color 150ms;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin: 4px 3px 4px 0px;
  background-color: transparent;
  border-radius: 4px;
  transition: transform 0.1s var(--expand-animation-timing);

  &__icon {
    transition: transform 0.1s var(--expand-animation-timing);
  }
}

.dropdown-indicator:hover {
  background-color: var(--primary-background-hover-color);
  color: var(--icon-color);
}

.clear-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-color);
  background-color: transparent;
  border-radius: var(--border-radius-small);
  height: 24px;
  width: 24px;
}

.clear-indicator:hover {
  background-color: var(--primary-background-hover-color);
}

.input {
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  visibility: visible;
  color: var(--primary-text-color);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 14px;
  background-color: var(--primary-background-color);
  display: flex;
  align-items: center;
  text-indent: -2px;

  input {
    cursor: inherit;
  }
}

.menu {
  top: 100%;
  background-color: var(--dialog-background-color);
  border-radius: var(--border-radius-small);
  box-shadow: var(--box-shadow-small);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 14px;
  color: var(--primary-text-color);
  animation: fadeIn 100ms cubic-bezier(0, 0, 0.35, 1);
}

.option {
  position: relative;
  font-size: inherit;
  line-height: 14px;
  color: var(--primary-text-color);
  cursor: pointer;
  display: flex;
  padding: 8px;
  user-select: none;
  box-sizing: border-box;
  align-items: center;
  border-radius: var(--border-radius-small);
  margin-right: 8px;
  margin-left: 8px;
  height: 35px;
  transition: all 0.1s var(--expand-animation-timing);

  span {
    display: flex;
    height: 100%;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.selectedOption {
  background-color: var(--primary-selected-color);
}

.option:hover {
  background-color: var(--primary-background-hover-color);
}

.container {
  position: relative;
  box-sizing: border-box;
  height: 32px;
  min-height: 30px;
  border: 1px solid var(--ui-border-color);
  box-shadow: none;
  border-radius: var(--border-radius-small);
  transition: border 0.1s var(--expand-animation-timing);
  max-width: 256px;
}

.placeholder {
  display: flex;
  align-items: center;
  height: inherit;
  position: absolute;
  text-align: left;
  color: var(--secondary-text-color);
}
