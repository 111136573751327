@import '~monday-ui-react-core/src/styles/themes.scss';
@import '../../variables.scss';

.ordered-list {
  &_dnd-btn {
    @include theme-prop(color, inverted-color-background);
    display: none;

    &:hover {
      cursor: grab;
    }
  }

  &_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    @include theme-prop(background, $color_bg);

    &:hover {
      @include theme-prop(background, primary-background-hover-color);
      .ordered-list_dnd-btn {
        display: block;
      }
    }

    &:active {
      .ordered-list_dnd-btn {
        display: block;
      }
    }

    .monday-style-checkbox__label {
      max-width: 190px;
    }

    &-wrapper {
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
