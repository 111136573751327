@import '~monday-ui-style/dist/index.min.css';
@import '~monday-ui-react-core/src/styles/themes.scss';
@import './variables.scss';

.main-app {
  height: 100%;
  @include theme-prop(color, secondary-text-color);

  &-header {
    width: calc(100% - 2px);
    position: absolute;
    text-align: left;
    z-index: 4;
    border-radius: 16px 16px 0 0;
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    @include theme-prop(background, $color_bg);
    @include theme-prop(border-color, layout-border-color);

    &__title {
      padding: 10px 0 10px 24px;
      margin: 0;

      font-weight: 400;
      font-size: 30px;
      line-height: 45px;
    }

    &__divider {
      margin: 0;
    }
  }

  &-container {
    text-align: center;
    display: flex;
    height: 100%;

    &.settings-mode {
      box-sizing: border-box;
      border-radius: 16px;
      border-bottom: 1px solid;
      border-left: 1px solid;
      border-right: 1px solid;
      @include theme-prop(border-color, layout-border-color);
    }
  }

  &_loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .settings-divider {
    margin: 0;
  }
}

.primary-text-color {
  @include theme-prop(color, primary-text-color);
}
