.empty-screen {
  flex: 1;
  align-self: center;

  &_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }

  &_img {
    width: 112px;

    @media (min-height: 426px) {
      height: 180px;
      width: 160px;
    }
  }

  &_aside {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &_title {
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    width: 228px;
  }
}
