@import '~monday-ui-react-core/src/styles/themes.scss';

.name {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  padding: 2px 0;
  width: 154px;
}

.inline-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
